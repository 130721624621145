<template>
  <v-card color="primary" class="card d-flex flex-column secondary--text" :to="{name: 'lang.index'}">
    <v-img src="@/assets/img/dummy/home/home.png" lazy-src="@/assets/img/dummy/home/home.png"></v-img>
    <div class="card-footer d-flex align-center justify-space-between px-15">
      <div>
        <v-img width="193" src="@/assets/home/icon.svg"></v-img>
      </div>
      <div>
        <h1 class="card-title">Tap to start</h1>
        <h1 class="card-title" dir="rtl">ءﺪﺒﻠﻟ ﺮﻘﻧا</h1>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style lang="scss" scoped>
.card{

  &-title{
    font-weight: bold;
    font-size: 72px;
    line-height: 84px;

  }
  &-footer{
    width: 100%;
    height: 293px;
  }
}
</style>
